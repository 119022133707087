import { useEffect } from "react";

function Policy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  useEffect(() => {
    document.title = 'Россия 21 век - политика обработки персональных данных';
  }, []);
  return (
    <div
      className="container"
      style={{ marginTop: '80px' }}
    >
      <h2>Политика обработки персональных данных</h2>
      <h3>1. ОПРЕДЕЛЕНИЯ И ТЕРМИНЫ</h3>
      <p>1.1. В Политике используются следующие основные определения и термины:</p>
      <p>
        Автоматизированная обработка Персональных данных – обработка Персональных данных с помощью
        средств вычислительной техники.
      </p>
      <p>
        Администрация – лицо, обеспечивающее функционирование, техническую поддержку, обновление
        Сервисов Сайта, и, оказывающее Услуги Сайта (ОСОО «Россия XXI век»)
      </p>
      <p>
        Блокирование Персональных данных – временное прекращение обработки Персональных данных (за
        исключением случаев, если обработка необходима для уточнения Персональных данных).
      </p>
      <p>
        Информационная система Персональных данных – совокупность содержащихся в базах данных
        Персональных данных, и, обеспечивающих их обработку информационных технологий и технических
        средств.
      </p>
      <p>
        Обезличивание Персональных данных – действия, в результате которых невозможно определить
        без использования дополнительной информации принадлежность Персональных данных конкретному
        Субъекту Персональных данных.
      </p>
      <p>
        Обработка Персональных данных – любое действие (операция) или совокупность действий
        (операций), совершаемых на Сайте с использованием средств автоматизации или без
        использования таких средств с Персональными данными, включая сбор, запись, систематизацию,
        накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
        (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
        уничтожение Персональных данных.
      </p>
      <p>
        Общедоступные Персональные данные – Персональные данные, доступные неограниченному кругу
        лиц.
      </p>
      <p>
        Оператор – лицо (Администрация Сайта), самостоятельно или совместно с другими лицами
        организующее и (или) осуществляющее обработку Персональных данных, а также определяющее цели
        обработки Персональных данных, состав Персональных данных, подлежащих обработке, действия
        (операции), совершаемые с Персональными данными.
      </p>
      <p>
        Персональные данные – любая информация, относящаяся прямо или косвенно к определенному
        физическому лицу (Субъекту Персональных данных) – Пользователю Сайта.
      </p>
      <p>Пользователь – любое лицо, посещающее Сайт.</p>
      <p>
        Предоставление Персональных данных – действия, направленные на раскрытие Персональных
        данных определенному лицу или определенному кругу лиц.
      </p>
      <p>
        Распространение Персональных данных – действия, направленные на раскрытие Персональных
        данных неопределенному кругу лиц (передача Персональных данных) или на ознакомление с
        Персональными данными неограниченного круга лиц, в том числе обнародование Персональных
        данных в средствах массовой информации, размещение в информационно-телекоммуникационных
        сетях или предоставление доступа к Персональным данным каким-либо иным способом.
      </p>
      <p>
        Регистрация на Сайте – ввод потенциальным Пользователем своих данных на Сайте для получения
        полного доступа к Сервисам Сайта.
      </p>
      <p>
        Сайт – совокупность программ для ЭВМ и иной информации, содержащейся в информационной
        системе, доступ к которой обеспечивается посредством Интернет по доменным именам
        rf21vek.ru
      </p>
      <p>
        Сервис Сайта – реализованное на Сайте техническое решение (заявка стать волонтёром),
        направленное на организацию в рамках Сайта эффективного взаимодействия Оператора и
        Пользователя или Пользователей между собой.
      </p>
      <p>Субъект Персональных данных – Пользователь-физическое лицо.</p>
      <p>
        Уничтожение Персональных данных – действия, в результате которых невозможно восстановить
        содержание Персональных данных в Информационной системе Персональных данных, и (или) в
        результате которых уничтожаются материальные носители Персональных данных.
      </p>
      <p>
        1.2. Содержащиеся в настоящей Политике определения и термины, не охваченные настоящим
        разделом Политики, используются в значении, предусмотренном законодательством Российской
        Федерации о защите Персональных данных.
      </p>
      <h3 id="agreement">2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <p>
        Настоящая Политика определяет порядок обработки Персональных данных и меры по обеспечению
        безопасности Персональных данных, предоставляемых Пользователями Сайта при работе с
        Сайтом.
      </p>
      <h3>3. СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
      <p>
        3.1. Субъект Персональных данных принимает решение о предоставлении его Персональных данных
        и дает согласие на их обработку свободно, своей волей и в своем интересе.
        {' '}
      </p>
      <p>
        Фактом Регистрации на Сайте и (или) использования Сервисов Сайта Субъект Персональных
        данных принимает настоящую Политику и подтверждает свое согласие на обработку его
        Персональных данных, предоставленных при Регистрации, а также данных, размещаемых на Сайте
        при его использовании.
      </p>
      <p>
        3.3. Размещая информацию о себе на Сайте, Субъект Персональных данных осознает и
        соглашается с тем, что указанная информация может быть доступна другим пользователям сети
        Интернет с учетом особенностей архитектуры и функций Сайта. При этом, размещение
        Пользователем персональных данных на Сайте осуществляется по инициативе самого Пользователя,
        который самостоятельно несёт ответственность за содержание размещенной им на Сайте
        информации.
      </p>
      <h3>4. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
      <p>4.1. Принципы обработки Персональных данных</p>
      <p>Обработка Персональных данных Оператором осуществляется на основе следующих принципов:</p>
      <ul>
        <li>законности;</li>
        <li>
          ограничения обработки Персональных данных достижением конкретных, заранее определенных и
          законных целей;
        </li>
        <li>
          недопущения обработки Персональных данных, несовместимой с целями сбора Персональных
          данных;
        </li>
        <li>
          соответствия содержания и объема обрабатываемых Персональных данных заявленным целям
          обработки;
        </li>
        <li>
          недопущения обработки Персональных данных, избыточной по отношению к заявленным целям их
          обработки;
        </li>
        <li>
          обеспечения точности, достаточности и актуальности Персональных данных по отношению к
          целям обработки Персональных данных;
        </li>
        <li>
          уничтожения либо обезличивания Персональных данных по достижении целей их обработки или
          в случае утраты необходимости в достижении этих целей.
        </li>
        <li>законности;</li>
      </ul>
      <p>4.2. Условия обработки Персональных данных</p>
      <p>
        Оператор производит обработку Персональных данных при наличии хотя бы одного из следующих
        условий:
      </p>
      <ul>
        <li>
          обработка Персональных данных осуществляется с согласия Субъекта Персональных данных на
          обработку его Персональных данных;
        </li>
        <li>
          обработка Персональных данных необходима для достижения целей, предусмотренных
          законодательством, для осуществления и выполнения возложенных на Оператора функций,
          полномочий и обязанностей;
        </li>
        <li>
          обработка Персональных данных необходима для осуществления правосудия, исполнения
          судебного акта, акта другого органа или должностного лица, подлежащих исполнению
          Оператором или Субъектом Персональных данных;
        </li>
        <li>
          обработка персональных данных необходима для исполнения договора, стороной которого либо
          выгодоприобретателем или поручителем по которому является Субъект Персональных данных, а
          также для заключения договора по инициативе Субъекта Персональных данных или договора, по
          которому Субъект Персональных данных будет являться выгодоприобретателем или поручителем;
        </li>
        <li>
          обработка Персональных данных необходима для осуществления прав и законных интересов
          оператора или третьих лиц либо для достижения общественно значимых целей при условии, что
          при этом не нарушаются права и свободы Субъекта Персональных данных;
        </li>
        <li>осуществляется обработка общедоступных Персональных данных;</li>
        <li>
          осуществляется обработка Персональных данных, подлежащих опубликованию или обязательному
          раскрытию в соответствии с законодательством.
        </li>
      </ul>
      <p>4.3. Общедоступные источники Персональных данных</p>
      <p>
        4.3.1. В целях информационного обеспечения на Сайте могут создаваться общедоступные
        источники Персональных данных Субъектов Персональных данных, в том числе справочники,
        информационные страницы Сайта и т.п. В общедоступные источники Персональных данных с
        согласия Субъекта Персональных данных могут включаться его фамилия, имя, отчество, дата и
        место рождения, должность, профессия, навыки, номера контактных телефонов, адрес электронной
        почты и иные Персональные данные, сообщаемые самим Субъектом Персональных данных.
      </p>
      <p>
        4.3.2. Общедоступные сведения о Субъекте Персональных данных могут быть в любое время
        исключены Оператором из общедоступных источников Персональных данных как по требованию
        самого Субъекта Персональных данных, так и по требованию уполномоченных органов.
      </p>
      <p>4.4. Специальные категории Персональных данных</p>
      <p>
        4.4.1. Обработка Оператором специальных категорий Персональных данных, касающихся расовой,
        национальной принадлежности, политических взглядов, религиозных или философских убеждений,
        состояния здоровья и т.п. допускается в одном из нижеперечисленных случаев. В частности, это
        возможно, если:
      </p>
      <ul>
        <li>
          Субъект Персональных данных дал Оператору согласие в письменной форме на обработку своих
          Персональных данных;
        </li>
        <li>Персональные данные сделаны общедоступными самим Субъектом Персональных данных;</li>
        <li>
          обработка Персональных данных необходима для установления или осуществления прав
          Субъекта Персональных данных или третьих лиц, а равно в связи с осуществлением правосудия.
        </li>
      </ul>
      <p>
        4.4.2. Если иное не установлено законодательством, обработка специальных категорий
        Персональных данных, осуществлявшаяся в вышеперечисленных случаях, должна быть
        незамедлительно прекращена, если устранены причины, вследствие которых осуществлялась их
        обработка.
      </p>
      <p>4.5. Поручение обработки Персональных данных другому лицу</p>
      <p>
        Если иное не предусмотрено законодательством, Оператор вправе поручить обработку
        Персональных данных другому лицу с согласия Субъекта Персональных данных на основании
        заключаемого с этим (другим) лицом договора. Лицо, осуществляющее обработку Персональных
        данных по поручению Оператора, обязано соблюдать принципы и правила обработки персональных
        данных, предусмотренные законодательством и настоящей Политикой.
      </p>
      <p>4.6. Обрабатываемые Персональные данные</p>
      <p>
        По общему правилу Оператор обрабатывает следующие персональные данные Пользователей:
        фамилия, имя, отчество, адрес электронной почты, фотографические и другие данные,
        размещенные Пользователем на Сайте, и, относящиеся к персональным данным.
      </p>
      <p>4.7. Срок обработки и хранения Персональных данных</p>
      <p>
        Персональные данные хранятся и обрабатываются в течение всего срока функционирования
        Сайта.
      </p>
      <p>4.8. Цели обработки Персональных данных</p>
      <p>Оператор обрабатывает персональные данные Пользователей в целях:</p>
      <ul>
        <li>оказания услуг Сайта; </li>
        <li>идентификации Пользователей;</li>
        <li>авторизации Пользователей на Сайте;</li>
        <li>
          предоставления Пользователям доступа к персонализированным ресурсам и сервисам Сайта;
        </li>
        <li>
          установления с Пользователями обратной связи, включая направление уведомлений, запросов,
          касающихся использования Сайта, обработку запросов и заявок от Пользователей;
        </li>
        <li>
          подтверждения достоверности и полноты персональных данных, предоставленных
          Пользователями;
        </li>
        <li>получения и обработки платежей;</li>
        <li>
          предоставления Пользователям эффективной клиентской и технической поддержки при
          возникновении проблем, связанных с использованием Сайта.
        </li>
      </ul>
      <p>4.9. Способы обработки Персональных данных</p>
      <p>
        Оператор реализует исключительно автоматизированную обработку Персональных данных, получая
        их от Пользователей посредством сети Интернет.
      </p>
      <p>4.10. Права и обязанности при обработке Персональных данных</p>
      <p>
        4.10.1. Субъект Персональных данных имеет право на получение у Оператора информации,
        касающейся обработки его Персональных данных. Субъект Персональных данных вправе требовать
        от Оператора уточнения его Персональных данных, их блокирования или уничтожения в случае,
        если Персональные данные являются неполными, устаревшими, неточными, незаконно полученными
        или не являются необходимыми для заявленной цели обработки, а также принимать
        предусмотренные законом меры по защите своих прав.
      </p>
      <p>
        4.10.2. Оператор обязан немедленно прекратить по требованию Субъекта Персональных данных
        обработку его Персональных данных.
      </p>
      <p>
        4.10.3. Запрещается принятие на основании исключительно автоматизированной обработки
        Персональных данных решений, порождающих юридические последствия в отношении Субъекта
        Персональных данных или иным образом затрагивающих его права и законные интересы, за
        исключением случаев, предусмотренных федеральными законами, или при наличии согласия в
        письменной форме Субъекта Персональных данных.
      </p>
      <h3>5. ЗАЩИТА И КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
      <p>5.1. Защита Персональных данных</p>
      <p>
        5.1.1. Защита Персональных данных, обрабатываемых Оператором, обеспечивается реализацией
        правовых, организационных и технических мер, необходимых для обеспечения требований
        законодательства в области защиты Персональных данных и предотвращения несанкционированного
        доступа к Персональным данным.
      </p>
      <p>
        5.1.2. Оператор принимает все доступные (с учетом существующей функциональности Сайта) меры
        для защиты Персональных данных Пользователей от неправомерного доступа, раскрытия или
        уничтожения.
      </p>
      <p>5.2. Конфиденциальность Персональных данных</p>
      <p>
        5.2.1. Оператор и иные лица, получившие на законном основании доступ к Персональным данным,
        не вправе раскрывать третьим лицам и распространять Персональные данные без согласия
        Субъекта Персональных данных, если иное не предусмотрено законодательством.
      </p>
      <p>
        5.2.2. Раскрытие предоставленных Пользователем персональных данных может быть произведено
        лишь в соответствии с действующим законодательством Российской Федерации по требованию суда,
        правоохранительных органов, а равно в иных предусмотренных законодательством случаях,
        требующих обязательного раскрытия информации. В остальных случаях персональные данные
        Пользователя могут быть предоставлены третьим лицам только с согласия Пользователя.
      </p>
      <h3>6. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
      <p>
        6.1. Иные права и обязанности Оператора в связи с обработкой Персональных данных
        определяются действующим законодательством.
      </p>
      <p>
        6.2. Оператор, виновный в нарушении норм, регулирующих обработку и защиту Персональных
        данных, несет ответственность в порядке и пределах, установленных законодательством.
      </p>
      <p>
        6.3. Настоящая Политика может быть изменена Оператором в одностороннем порядке без
        какого-либо специального уведомления Пользователей Сайта. Изменения в Политику становятся
        обязательными с момента их опубликования на Сайте.
        Использование Сайта Пользователем после внесения изменений в настоящую Политику означает
        принятие и согласие Пользователя с такими изменениями.
      </p>
      <p>Политика введена в действие 01.09.2019</p>
    </div>
  );
}

export default Policy;
